import Link from "next/link";
import { useEffect, useState } from "react";
import { ScrollTop } from "../styles/BodyStyle";
import { ContactWrapper, Logo, LogoWrapper, menuFixed_top, menuToggle, MenuTrigger, Nav, NavbarCollaps, NavbarItem, NavbarItemMain, NavLink } from "../styles/HeaderStyles";
import styles from "../styles/Home.module.css";

const Navbar = () => {
    const [services, setService]= useState([])
    useEffect(() => {
        const fetchData = service()
        fetchData.then((response) => {
            setService(response.data)
            console.log(response.data);});
        
        var toggle_menu_btn = document.querySelector(`${MenuTrigger}`);
        var toggle_menu_list = document.querySelector(`${NavbarCollaps}`);
        toggle_menu_btn.onclick = () => { menuToggle(toggle_menu_btn, toggle_menu_list) }


        var scrollTop = document.querySelector(`${ScrollTop}`);


        var fixed_top = document.querySelector(`${Nav}`);
        window.onscroll = () => {
            menuFixed_top(fixed_top)

            if (document.body.scrollTop > 25 || document.documentElement.scrollTop > 25) {
                scrollTop.style.display = "block";
            } else {
                scrollTop.style.display = "none";
            }
        }

    }, [])

    const scrollTotop = (e) => {
        e.preventDefault();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
          });
    }

    return (<>
        <header className={`${styles.dark_bb} navbar-dark`}>
            <Nav>
                <LogoWrapper>
                    <Logo src="/medville-041.png" alt="logo" />
                </LogoWrapper>
                <MenuTrigger type="button" data-bs-toggle="collapse" data-bs-target="#headerMenu" aria-controls="headerMenu" aria-expanded="false" aria-label="Toggle navigation" className="collapsed">
                    <span></span>
                    <span></span>
                    <span></span>
                </MenuTrigger>

                <NavbarCollaps id="headerMenu">

                    <NavbarItemMain>
                        <NavbarItem>
                            <Link href='/'>
                                <NavLink role="button">
                                    Home
                                </NavLink>
                            </Link>
                        </NavbarItem>
                        <NavbarItem>
                            <Link href='#'>
                                <NavLink role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Services
                                </NavLink>
                            </Link>
                            <ul className={`${styles.menu_dropdown} `}>
                                {services && services.map(service =>(
                                    <Link href={`/services/${service.id}`} key={service.id}>
                                    <a className={`${styles.items}`}> {service.title}</a>
                                </Link>
                                )
                                )}
                               
                            </ul>

                        </NavbarItem>
                        <NavbarItem>
                            <Link href='/projects'>
                                <NavLink role="button">
                                    Projects
                                </NavLink>
                            </Link>
                        </NavbarItem>
                        <NavbarItem>
                            <Link href='/products'>
                                <NavLink role="button">
                                    Products
                                </NavLink>
                            </Link>
                        </NavbarItem>
                        <NavbarItem>
                            <Link href='/career'>
                                <NavLink role="button">
                                    Career
                                </NavLink>
                            </Link>
                        </NavbarItem>
                        <NavbarItem>
                            <Link href='#'>
                                <NavLink role="button" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About
                                </NavLink>
                            </Link>
                            <ul className={`${styles.menu_dropdown} `}>
                               <li>
                                 <Link href={`/about/social_value`}>
                                    <a className={`${styles.items}`}>Social Value</a>
                                </Link>
                               </li>
                               <li>
                                 <Link href={`/about/team`}>
                                    <a className={`${styles.items}`}>Team</a>
                                </Link>
                               </li>
                               <li>
                                 <Link href={`/about/vision`}>
                                    <a className={`${styles.items}`}>Vision</a>
                                </Link>
                               </li>
                                   
                              
                               
                            </ul>

                        </NavbarItem>

                    </NavbarItemMain>
                    <NavbarItemMain>
                        <NavbarItem>
                            <Link href='#contact'>

                                <ContactWrapper role="button">
                                    Contact us
                                </ContactWrapper>


                            </Link>
                        </NavbarItem>

                    </NavbarItemMain>

                </NavbarCollaps>
            </Nav>
        </header>
        <ScrollTop onClick={scrollTotop}><i className="fi fi-rr-angle-small-up"></i></ScrollTop>
    </>);
}
export default Navbar;

export const service = async () => {
    const res = await fetch('https://api.medvilleengineering.com/getData/getServices')
    const data = await res.json()
    return {
             data
            }
  }
