import Link from "next/link";
import { useEffect, useState } from "react";
import axios from "axios"
import { Base, Column, Error, Footer, FooterComapnyName, FooterCopyright, FooterInner, FooterLink, FooterList, FooterLogo, FooterLogoWrapper, Form, FormButton, FormInput, GmapCanvas, Heading, Input, LinkHeading, LinkWrapper, Mapdata, Mapouter, MarginBottom, MarginRightSmall, Pending, Row, RowColumn, SocialLink, SocialLinkWrapper, SubHeading, Success, Text, TextArea } from "../styles/FooterStyle";

const PageFooter = () => {
        const [footerData, setFooterData] = useState([])
        const [name, setName] = useState('')
        const [email, setEmail] = useState('')
        const [phone, setPhone] = useState('')
        const [title, setTitle] = useState('')
        const [message, setMessage] = useState('')
        const [error, setError] = useState()

        const sendMesssage = (e)=>{
                e.preventDefault()
                setError(<><Pending>Please Wait...</Pending></>)
                        setTimeout(()=>{ setError()},10000)
                if (name === undefined || email === undefined || phone === undefined || message === undefined || name === "" || email === "" || phone === "" || message === ""){
                        setError(<><Error>Please check that all fields are filed correctly</Error></>)
                        setTimeout(()=>{ setError()},10000)
                }
                else{
                 axios.get(`https://api.medvilleengineering.com/mailer/contactMail?name=${name}&email=${email}&phone=${phone}&subject=${title}&message=${message}`)
                .then((res) => {
                      console.log(res.data)   
                })
                .catch((err) =>{
                        console.log(err.response)
                        setError(<><Error>An error occurred! Please try again later</Error></>)
                        setTimeout(()=>{ setError()},10000)
                })
               .finally(()=>{
                        setName("");
                        setMessage("");
                        setEmail("");
                        setPhone("");
                        setMessage(""); 
                        setError(<><Success>Message Recieved, Kindly do not resend your request to prevent unwanted queue!</Success></>)
                      setTimeout(()=>{ setError()},10000)
               })
                
                }
              
                

        }
        useEffect(() => {
                const fetchData = async () => {
                        await axios.get(`https://api.medvilleengineering.com/getData/siteInfo`)
                                .then((response) => { return response.data })
                                .then((data) => {
                                        setFooterData(data)
                                })

                }
                fetchData()
        }, [])
        return (<>
                <Footer>
                        <FooterInner>
                                <RowColumn style={{alignItems: 'center'}}>
                                        <Column id="contact">

                                                <Heading>Contact Us</Heading>
                                                <Text>We are always here to help you</Text>
                                                <MarginBottom />
                                                <Form id="contact" onSubmit={sendMesssage}>
                                                        <FormInput> <Input type="text" placeholder="Name" value={name} onChange={(e)=>(setName(e.target.value))}/></FormInput>
                                                        <FormInput><Input type="text" placeholder="Email" value={email}  onChange={(e)=>(setEmail(e.target.value))}/></FormInput>
                                                        <FormInput> <Input type="tel" placeholder="Whatsapp Number"  value={phone}  onChange={(e)=>(setPhone(e.target.value))}/></FormInput>
                                                        <FormInput> <Input type="text" placeholder="Subject" value={title} onChange={(e)=>(setTitle(e.target.value))}/></FormInput>
                                                        <FormInput><TextArea placeholder="Message" rows="4" cols="50" maxLength={200} value={message} onChange={(e)=>(setMessage(e.target.value))}/></FormInput>
                                                        {error}
                                                        <FormInput><FormButton>Send</FormButton></FormInput>
                                                        
                                                </Form>
                                        </Column>
                                        <Mapouter>
                                                <GmapCanvas>
                                                        <Mapdata id="gmap_canvas" src={`https://maps.google.com/maps?q=${footerData.address}&t=&z=13&ie=UTF8&iwloc=&output=embed`} frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></Mapdata>

                                                </GmapCanvas>
                                        </Mapouter>

                                </RowColumn>
                                <MarginBottom />
                                <RowColumn>
                                        <FooterList style={{ justifyContent: 'center' }}>
                                                <Column>
                                                        <FooterLogoWrapper>
                                                                <FooterLogo src="/medville-03.png" alt="Medville" />
                                                        </FooterLogoWrapper>
                                                        <FooterComapnyName>{footerData.name}</FooterComapnyName>
                                                </Column>
                                        </FooterList>
                                        <FooterList>
                                                <Column>
                                                        <LinkHeading>About Us</LinkHeading>
                                                        <LinkWrapper>
                                                                <Link href="/about/vision">
                                                                        <FooterLink role="button">Vision</FooterLink >
                                                                </Link>
                                                                <Link href="/about/social_value">
                                                                        <FooterLink role="button">Social Value</FooterLink >
                                                                </Link>
                                                                <Link href="/about/team">
                                                                        <FooterLink role="button">Team</FooterLink >
                                                                </Link>
                                                             
                                                        </LinkWrapper>

                                                </Column>
                                        </FooterList>
                                        <FooterList>
                                                <Column>
                                                        <LinkHeading>Reach Us</LinkHeading>
                                                        <LinkWrapper>

                                                                <FooterLink role="button" href={`mailto:${footerData.email}`}>
                                                                        <MarginRightSmall><i className="fi fi-rr-envelope"></i></MarginRightSmall>
                                                                        {footerData.email}
                                                                </FooterLink >

                                                                <FooterLink role="button" href={`tel:${footerData.phone}`}>
                                                                        <MarginRightSmall><i className="fi fi-rr-phone-call"></i></MarginRightSmall>
                                                                        {footerData.phone}
                                                                </FooterLink >


                                                                <FooterLink role="button">
                                                                        <MarginRightSmall><i className="fi fi-rr-location-alt"></i></MarginRightSmall>
                                                                        {footerData.address}
                                                                </FooterLink >


                                                        </LinkWrapper>

                                                </Column>
                                        </FooterList>

                                        <FooterList style={{ justifyContent: 'center' }}>
                                                <Column>
                                                        <SubHeading>Subscribe to our News Letters</SubHeading>
                                                        <LinkWrapper>
                                                                <Form>

                                                                        <FormInput> <Input type="text" placeholder="Email Address" /></FormInput>
                                                                        <FormInput><FormButton>Subscribe</FormButton></FormInput>
                                                                </Form>



                                                        </LinkWrapper>
                                                        <Column>
                                                                <SocialLinkWrapper>
                                                                     
                                                                                <SocialLink role="button" target="_blank" rel="noopener noreferrer"  href={`https://facebook.com/${footerData.facebook}`}><i className="fi fi-brands-facebook"></i></SocialLink >
                                                                       
                                                                                <SocialLink role="button" target="_blank" rel="noopener noreferrer"  href={`https://twitter.com/${footerData.twitter}`}><i className="fi fi-brands-twitter"></i></SocialLink >
                                                                        
                                                                                <SocialLink role="button" target="_blank" rel="noopener noreferrer" href={`https://instagram.com/${footerData.instagram}`}><i className="fi fi-brands-instagram"></i></SocialLink >
                                                                      

                                                                </SocialLinkWrapper>

                                                        </Column>

                                                </Column>
                                        </FooterList>

                                </RowColumn>

                        </FooterInner>
                </Footer>
                <RowColumn style={{ alignItems: 'center' }}>
                        <Row>
                                <Link href='/privacy'><Base>Privacy Policy</Base></Link>
                                <Link href="#"><Base>Terms and Conditions</Base></Link>
                        </Row>
                        <FooterCopyright>&copy; {footerData.name}</FooterCopyright>
                </RowColumn>

        </>);
}

export default PageFooter;