import { useEffect } from "react";
import { Column, Container, ContainerInner, Wrapper } from "../styles/BodyStyle";
import PageFooter from "./NavFooter";
import Navbar from "./NavHeader";
const Layout = ({children}) => {
    useEffect(() => {
        function reveal() {
          var reveals = document.querySelectorAll(`${Column}`);
          for (var i = 0; i < reveals.length; i++) {
            var windowHeight = window.innerHeight;
            var elementTop = reveals[i].getBoundingClientRect().top;
            var elementVisible = 50;
            if (elementTop < windowHeight - elementVisible) {
              reveals[i].classList.add("reveal");
            } else {
              reveals[i].classList.remove("reveal");
            }
          }
        }
        
        window.addEventListener("scroll", reveal);

      }, [])
    return ( <>
    <Navbar/>
    <Wrapper>
        <Container>
            <ContainerInner>
                {children}
            </ContainerInner>
        </Container>
    </Wrapper>
    <PageFooter/>
    
    </> );
}
 
export default Layout;
