import styled from 'styled-components';
export const Footer = styled.footer`
    background-color: var(--primary);
    padding: 4rem 10rem 0;
    text-align: center;
    font-size: 0.8rem;
    color: var(--white);
    border-top: 1px solid #eee;
    margin-top: 1rem;
    @media (max-width: 768px) {
        padding: 2rem 1rem 0;
    }
    `
    export const FooterInner = styled.div`
    display: flex;
    flex-direction: column;`
    export const Row = styled.div`
    display: flex;
    flex-direction: row;
    `
    export const RowColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    @media (min-width: 768px) {
        flex-direction: row;
    }
    `
    export const Column = styled.div`
    display: flex;
    flex-direction: column;
    `
    export const FormInput = styled.div`
    padding: 0 20px;
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    position: relative;
    min-height: 12px;
    margin-bottom: 1.3rem!important;`
    export const InputWrapper = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: inline-flex;
    position: relative;
    -webkit-box-align: center;
    align-items: center;
    line-height: 1.6;
    border: 1px solid rgb(71, 77, 87);
    border-radius: 4px;
    height: 48px;
    background-color: transparent;
    width: 100%;`
    export const Input = styled.input`
    width: -webkit-fill-available;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    background-color: var(--white);
    color: var(--gray);
    font-size: 15px;
    font-weight: 400;
    outline: none;
    ::placeholder {
        color: #676AD2;
        font-size: 14px;
        font-weight: 400;
    }
    :focus {
border: 1px solid #676AD2;
    }
    `
    export const TextArea = styled.textarea`
    width: -webkit-fill-available;
    padding: 10px 20px;
    border: none;
    border-radius: 25px;
    background-color: var(--white);
    color: var(--gray);
    font-size: 15px;
    font-weight: 400;
    outline: none;
    ::placeholder {
        color: #676AD2;
        font-size: 14px;
        font-weight: 400;
    }
    :focus {
border: 1px solid #676AD2;
    }`

    export const Form = styled.form`
    margin: 0 auto;
    max-width: 450px;
    @media (max-width: 768px) {
        max-width: 350px;
    }`

    export const FormButton = styled.button`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 50%;
    position: relative;
    min-height: 12px;
    margin-bottom: 0.2rem!important;
    background-color: var(--primary);
    color: var(--white);
    border: 2px solid var(--white);
    border-radius: 25px;
    height: 48px;
    font-size: 15px;
    font-weight: 600;
    outline: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    :hover {
        color: var(--primary);
        background-color: var(--white);
    }
    `
    export const Heading = styled.div`
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--white);
    padding: 10px 0px;`
    export const LinkHeading = styled(Heading)`
     text-align: start;`
    export const SubHeading = styled(Heading)`
    font-size: 1rem;
    font-weight: 300;`
    export const Text = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: var(--white);`

    export const MarginBottom = styled.div`
    margin-bottom: 1.5rem;`
    export const MarginTop = styled.div`
    margin-top: 1.5rem;`
    export const MarginBottomSmall = styled.div`
    margin-bottom: 0.5rem;`
    export const MarginTopSmall = styled.div`
    margin-top: 0.5rem;`
    export const MarginBottomMedium = styled.div`
    margin-bottom: 1rem;`
    export const MarginTopMedium = styled.div`
    margin-top: 1rem;`
    export const MarginRightSmall = styled.div`
    margin-right: 0.5rem;`
    export const MarginLeftSmall = styled.div`
    margin-left: 0.5rem;`

    export const Mapouter = styled.div`
    position:relative;
    text-align:right;
    height:300px;
    width:350px;
    @media (min-width: 992px) {
        width: 600px;
        height: 500px;
    }
    `
    export const GmapCanvas = styled.div`
    overflow:hidden;
    background:none!important;
    height:300px;
    width:350px;
    @media (min-width: 992px) {
        width: 600px;
        height: 500px;
    }`
    export const Mapdata = styled.iframe`
    height:300px;
    width:350px;
    @media (min-width: 992px) {
        width: 600px;
        height: 500px;
    }`
    export const FooterList = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    width: 100%;
    padding: 10px;
    position: relative;
    @media screen and (min-width: 767px)
    {
       width: calc(25% - 16px);
       padding: 16px;
   }
`
export const LinkWrapper =styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    overflow: hidden;
    transition: all 0.3s ease 0s;
    max-height: 10em;
    @media screen and (min-width: 767px)
{
    max-height: 40em;
}`
export const SocialLinkWrapper = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;`
export const SocialLink = styled.a`
text-decoration: none;
box-sizing: border-box;
margin: 0px 20px 24px 20px;
min-width: 0px;
display: inline-flex;
color: rgb(226 225 255);
cursor: pointer;
:hover{
    color: var(--white);
}`
export const FooterLink = styled.a`

    box-sizing: border-box;
    margin: 0px 0px 4px;
    min-width: 0px;
    display: flex;
    font-size: 14px;
    color: rgb(226 225 255);
    cursor: pointer;
    text-align: start;
:hover{
    color: var(--white);
}
    @media screen and (min-width: 767px)
 {
    margin-bottom: 8px;
}

`
export const FooterLogoWrapper = styled.div`
max-width: 150px;
padding: 20px 20px 0 20px;`
export const FooterLogo = styled.img`
max-width: 100%;
max-height: 100%;`
export const FooterComapnyName = styled.div`
font-size: 0.5rem;
    text-transform: uppercase;
    margin-top: -10px;
`
export const Base = styled.a`
    box-sizing: border-box;
    margin: 0px;
    padding: 0 10px;
    font-size: 0.75rem;
    color: var(--primary);
    cursor: pointer;
    `
export const FooterCopyright = styled.div`
font-size: 0.75rem;
    text-transform: uppercase;
    margin:0 10px;
    color: var(--primary);`

    export const Error = styled.div`
    color: var(--failed);
    font-style: italic;
    `
    export const Success = styled.div`
    color: var(--success);
    font-style: italic;
    `
    export const Pending = styled.div`
    color: var(--warning);
    font-style: italic;
    `