import styles  from '../styles/LoadingScreen.module.css'
const Loader = () => {
    return (<>
        <div className={styles.container}>
            <div className={styles.spinner_blocks}>
                <div className={styles.ldio_oefckr7uivn}>
                    <div style={{ left: '38px', top: '38px', animationDelay: '0s' }}>
                    </div>
                    <div style={{ left: '80px', top: '38px', animationDelay: '0.125s' }}>
                    </div>
                    <div style={{ left: '122px', top: '38px', animationDelay: '0.25s' }}>
                    </div>
                    <div style={{ left: '38px', top: '80px', animationDelay: '0.875s' }}>
                    </div>
                    <div style={{ left: '122px', top: '80px', animationDelay: '0.375s' }}>
                    </div>
                    <div style={{ left: '38px', top: '122px', animationDelay: '0.75s' }}>
                    </div>
                    <div style={{ left: '80px', top: '122px', animationDelay: '0.625s' }}>
                    </div>
                    <div style={{ left: '122px', top: '122px', animationDelay: '0.5s' }}>

                    </div>
                </div>
            </div>
        </div>
    </>);
}

export default Loader;