import styled from 'styled-components';
export const Nav = styled.nav`
    padding: 0.5rem 1rem!important;
    border: 0;
    border-radius: 0;
    margin-bottom: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color: var(--background-color);
@media (min-width: 992px)
 {
    background-color: transparent;
    flex-wrap: nowrap;
    justify-content: flex-start;
}
`
export const NavbarCollaps = styled.div`


    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    justify-content: space-around;

    :not(.show) {
        display: none;
    }

    
    @media (min-width: 992px)
{
    display: flex!important;
    flex-basis: auto;
}
.fr{
    margin-right: auto!important;
}
.fl{
    margin-left: auto!important;
}
`
export const NavbarItemMain = styled.div`

    color: var(--font-color);
    margin: 0;
    pointer-events: auto;
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-width: 0;
    
    @media screen and (min-width: 767px)
 {
    pointer-events: auto;
    visibility: visible;
}
@media screen and (min-width: 992px){
    flex-direction: row;
}
`
export const LogoWrapper =styled.a`
padding-top: 0.3125rem;
padding-bottom: 0.3125rem;
margin-right: 1rem;
font-size: 1.25rem;
text-decoration: none;
white-space: nowrap;`

export const DisplayMobile = styled.div`
@media (min-width: 768px){
display:none;
}
display: flex;
`
export const UserLogoMobile = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-size: 14px;
    outline: none;
    cursor: default;
    color: #edeff1;
    align-items: center;
    position: relative;
    text-decoration: none;
    margin-left: 8px;
    margin-right: 8px;
    height: 100%;
    @media (min-width: 768px){
        display:none;
        }
        display: flex;
`
export const DropdownMobile = styled.ul`
box-sizing: border-box;
margin: 0px;
background-color: rgb(30, 35, 41);
position: absolute;
box-shadow: rgb(0 0 0 / 8%) 0px 0px 20px;
overflow: hidden;
border-radius: 0px 0px 8px 8px;
width: 100%;
min-width:300px;
left: 0 !important;`

export const Button= styled.button`
padding: 7px 10px;
    font-size: 14px !important;
    color:#1e1f23;
    font-weight: 500;
    background-color: #ffc107;
    border-color: #ffc107;
    cursor: pointer;
    line-height: 14px;
    position: relative;
    transition: all 0.2s ease-in-out;
    text-transform: none;
    margin: 0;
    border-radius: 0.25rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    display: flex;
    align-items: center;
    :hover{
        background-color: rgb(255 201 67);
    color: #181a20;
    }
    :focus{
        box-shadow: 0 0 3px 0px #fdeab0;
        color: #181a20;
    }
    i{
        margin-right:5px
    }`

    export const MenuTrigger= styled.button`
    position: relative;
    width: 25px;
    height: 20px;
    background: none;
    border: none;
    appearance: none;
    cursor: pointer;
    margin-right: 1%;
:not(:disabled) {
    cursor: pointer;
}
span {
    display: inline-block;
    transition: all 0.4s;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--link-color);
    border-radius: 4px;
    

}
span:nth-of-type(1) {
    top: 0;
    width:60%;
    margin-left: 10px;
}
span:nth-of-type(2) {
    top: 9px;
    width:40%;
    margin-left: 14px;
}
span:nth-of-type(3) {
    bottom: 0;
    width:100%;
    margin-left: 0;
}

:hover{
    span:nth-of-type(1) {
        margin-left: 0;
    }
    span:nth-of-type(2) {
        margin-left: 0;
    }
    span:nth-of-type(3) {
        margin-left: 0;
    } 
}
:not(.collapsed){
    span:nth-of-type(1) {
        transform: rotate3d(0, 0, 1, 45deg) translate3d(0, 0, 0);
        top: 8px;
        width: 100%;
        margin: 0;
    }
    span:nth-of-type(2) {
        opacity: 0;
    }
    span:nth-of-type(3) {
        transform: rotate3d(0, 0, 1, -45deg);
        top: 8px;
        margin: 0;
    } 
}

@media (min-width: 992px)
{
    display: none;
}`

 export const Logo = styled.img`
 width: 100px;`

 export const NavbarItem = styled.div`
 box-sizing: border-box;
 margin: 0;
 min-width: 0;
 display: flex;
 font-size: 14px;
 outline: none;
 cursor: default;
 color: #edeff1;
 align-items: center;
 position: relative;
 text-decoration: none;
 margin-left: 8px;
 margin-right: 8px;
 height: 100%;
ul{
    visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  left: 0;
  display: none;
  z-index:99;
}
:hover,:focus-within {
    ul{
         visibility: visible;
  opacity: 1;
  display: block;
    }
 
}
 `

export const NavLink = styled.a`
    color: var(--font-color);
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    margin: 0;
    padding: 13px 5px;
    line-height: 14px;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    :hover{
        border-bottom: 3px solid var(--primary);
        line-height: 0;
    }
    :active, :focus {
        font-weight: 700
    }
    @media (max-width: 992px) {
        :hover{
        border:none;
        color: var(--primary);
        line-height: 1;
        cursor: pointer;
        }
    }
`
export const ContactWrapper = styled.a`
border: 3px solid var(--primary);
padding: 5px 20px;
border-radius: 30px;
color: var(--primary);
font-weight: 600;
font-size: 14px;
text-transform: uppercase;
margin: 0;
cursor: pointer;
transition: all 0.2s ease-in-out;
:hover{
    background-color:var(--primary);
    color: #fff;
    transition: all 0.2s ease-in-out;
}
`

export const menuToggle = (toggle_menu_btn, toggle_menu_list)=>{
    toggle_menu_btn.classList.toggle('collapsed')
    toggle_menu_list.classList.toggle('show')
}

export const menuFixed_top = (fixed_top)=>{
    if (document.body.scrollTop > 25 || document.documentElement.scrollTop > 25){
        fixed_top.classList.add('fixedTop')
        fixed_top.classList.add("fadeInUp")
    }
    else {
        fixed_top.classList.remove('fixedTop');
        fixed_top.classList.remove("fadeInUp")
    }
}