import styled from "styled-components";

export const Wrapper = styled.div`
overflow: hidden;
position: relative;
`
export const Container = styled.div`

    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    min-width: 0;
    flex-direction: column;
    margin: 60px 0 0;
    padding-bottom: 70px;
    
    @media screen and (min-width: 992px)
 {
    padding-bottom: 0;
}
`
export const HomeBanner = styled.div`
background: url(/homeBanner.svg) center center / cover no-repeat;
height: 785px;
width: 100%;
display: inline-block;
max-height: max-content;
margin-top: -70px;
`
export const PageBanner = styled.div`
background: url(/pageBanner.svg) center center / cover no-repeat;
height: 700px;
width: 100%;
display: inline-block;
max-height: max-content;

@media screen and (min-width: 992px){
    width: 100%;
}
`
export const PageBannerContainer = styled.div`
height: 100%;
width: 100%;
margin-top: -60px;
background: #4a749c57;
border-radius: 0 0 20px 0;
padding-top: 60px;`
export const BannerContainer = styled.div`
margin-top: 60px;
padding: 50px 10px;
display: flex;
flex-direction: row;
@media screen and (min-width: 992px){
    padding: 100px 0;
}
`
export const BannerLeft = styled.div`
flex: 1 1;
display: flex;
flex-direction: column;
width: 100%;
@media screen and (min-width: 992px){
    width: 50%;
}

`
export const BannerRight = styled.div`
flex: 1 1;
display: flex;
flex-direction: column;
width: 100%;
align-items: center;
@media screen and (min-width: 992px){
    width: 50%;
}

`
export const ContainerInner = styled.div`  
    flex-direction: column;
    margin: 0;
    min-height: 600px;
    box-sizing: border-box;
    display: flex;
    flex: 1 1;
    min-width: 0;
`
export const MainContainer = styled.div`
box-sizing: border-box;
margin: 0 auto;
max-width: 1248px;
min-width: 0;
padding-left: 16px;
padding-right: 16px;
@media screen and (min-width: 992px)
{
    padding-left: 24px;
    padding-right: 24px;
}

   
`
export const ContainerContent = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
padding-top: 40px;
padding-bottom: 40px;
-webkit-font-smoothing: antialiased;
border-radius: 24px 24px 0px 0px;
z-index: 2;
@media screen and (min-width: 992px)
{
    padding-top: 45px;
    padding-bottom: 45px;
}
@media screen and (min-width: 1023px)
 {
    padding-top: 50px;
    padding-bottom: 50px;
}
`

export const Heading = styled.div`
font-size: 20px;
    text-transform: uppercase;
    margin: 20px 0 10px 0;
    color: var(--font-color);
    font-weight: 600;
@media screen and (min-width: 992px){
    font-size: 30px;
    font-weight: 600;
    margin: 20px 0 ;
}`
export const BodyHeading = styled.div`
font-size: 20px;
    margin: 20px 0 10px 0;
    color: var(--font-color);
    font-weight: 600;
@media screen and (min-width: 992px){
    font-size: 30px;
    font-weight: 600;
    margin: 15px 0 ;
}`


export const HeadingBoldBg = styled.div`
font-size: 40px;
font-weight: 600;
color: var(--font-color);
margin: 10px 0;
line-height: 1.2;
@media screen and (min-width: 992px){
    font-size: 60px;
font-weight: 700;
}`
export const PageHeadingBoldBg = styled(HeadingBoldBg)`
color: var(--white);`
export const HeadingText = styled.div`
font-size: 20px;
line-height: 1.5;
margin: 20px 0 30px 0;
@media screen and (min-width: 992px){
    font-size: 24px;
}
`
export const PageHeadingText = styled(HeadingText)`
color: var(--white);`
export const Button = styled.a`
border: 3px solid var(--primary);
padding: 5px 20px;
border-radius: 30px;
color: var(--primary);
font-weight: 600;
font-size: 14px;
text-transform: uppercase;
margin: 0;
cursor: pointer;
transition: .1s;
margin: 0 5px 0 0;
:hover{
    background-color:var(--primary);
    color: #fff;
    transition: .1s;
}
`
export const ButtonInverse = styled.a`
border: 3px solid var(--primary);
padding: 5px 20px;
border-radius: 30px;
color: #fff;
font-weight: 600;
font-size: 14px;
text-transform: uppercase;
margin: 0;
cursor: pointer;
transition: .1s;
background-color:var(--primary);
margin: 0 5px 0 0;
:hover{
    background-color:transparent;
    color: var(--primary);
    transition: .1s;
}
`
export const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
`

export const RowColumn = styled.div`
display: flex;
flex-direction: row;
@media screen and (min-width: 992px){
    flex-direction: column;
    }`
    export const Row = styled.div`
display: flex;
flex-direction: row;
    `
export const Column = styled.div`
display: flex;
flex-direction: column;
flex: 1 1 0%;
:not(.reveal) {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: 1s all ease;
}
:is(.reveal){
    opacity: 1;
    transition: 1s all ease;
}
`

export const ColumnRow = styled.div`
display: flex;
flex-direction: column;
@media screen and (min-width: 992px){
    flex-direction: row;
        }`

export const ColumnRowLists = styled(ColumnRow)`
flex-flow: wrap;`

export const ProductList = styled(ColumnRowLists)`
@media screen and (min-width: 992px){
    flex-flow: wrap;
    order: 0;
}
flex-flow: wrap;
order: 1;
`


        export const CardImage = styled.div`
        background: url("${props => props.image}") center center no-repeat;
        height: 350px;
        width: 310px;
        border-radius: 20px;
        box-shadow: 0px -1px 10px 0px #c8c8c8;
        margin-left: 0;
        margin-top: -20%;
        
        @media screen and (min-width: 992px){
        height: 500px;
        border-radius: 50px;
        margin-top:0px;
        margin-left: 15%;
        width: 95%;
        padding: 0px;
        box-shadow: 4px 0px 10px 0px #c8c8c8;}`

        export const CardImageInverse = styled(CardImage)`
       
        margin-top: 10%;
        margin-bottom: 10px;
        box-shadow: 0px -4px 10px 0px #6565657a;
        display: none;
       
        @media screen and (min-width: 992px){
            height: 500px;
            border-radius: 50px;
            margin-top: 20%;
            margin-left: 0;
            padding: 0px;
            box-shadow: -4px 0px 10px 0px #33333347;
            z-index: 99;
            width: 450px;
            display: block;
        
        `
        
export const ColumnRowLeft = styled.div`

    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    align-items: flex-end;
    flex: 1 1 0%;
    order: 2;
@media screen and (min-width: 992px)
 {
    order: 2;
}
@media screen and (min-width: 1023px)
{
    order: 1;
}

    `
export const ColumnRowRight = styled.div`
    box-sizing: border-box;
    margin: 0px 0px 40px;
    min-width: 0px;
    flex: 1 1 0%;
    order: 1;
@media screen and (min-width: 992px)
 {
    order: 1;
}
@media screen and (min-width: 1023px)
 {
    order: 2;
    margin-bottom: 0px;
}
    `
export const ShortLine = styled.div`
background: var(--primary);
height: 5px;
width: 15%;`

export const Md= styled.div`

@media screen and (min-width: 992px)
{
    width: 100%;
}
@media screen and (min-width: 1023px)
{
    width: 66.6667%;
}
`
export const CardText = styled.div`
background: url("/images/cardInner.svg") center center no-repeat, #50529f0d;
padding: 20px 20px 50px 20px;
border-radius: 20px;
@media screen and (min-width: 992px){
   padding: 46px 30px 40px 90px; 
   height: 640px;
}`
export const TextCenter = styled.div`
    text-align: center;`
export const TextLeft = styled.div`
    text-align: left;`
export const TextRight = styled.div`
    text-align: right;`
export const TextJustify = styled.div`
    text-align: justify;`

export const WidePager = styled.div`
width: 100%;
height: max-content;
background: url("/images/fullBack.svg") center center no-repeat, #F2F2F2;
background-size: cover;`

export const CardTable = styled.div`
box-sizing: border-box;
display: flex;
margin: 0;
min-width: 0;
padding: 8px;
flex-wrap: wrap;
justify-content: space-between;

`
export const BlockThird = styled.div`
    position: relative;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    margin: 0;
    min-width: 0;
    padding: 8px;
    @media screen and (min-width: 992px)
 {
    width: 33.3%;
}
@media screen and (min-width: 1023px)
{
    width: 33.3%;
}
`

export const BlockQuater = styled.div`
    position: relative;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    margin: 0;
    min-width: 0;
    padding: 8px;
    
    @media screen and (min-width: 992px)
 {
    width: 25%;
}
@media screen and (min-width: 1023px)
{
    width: 25%;
}
`
export const Card= styled.div`
background-color: var(--card-background-color);
border-radius: 10px;
box-sizing: border-box;
margin: 0;
min-width: 0;
padding: 70px 16px;
width: 100%;
:hover{
    box-shadow: var(--card-shadow);
    transition: .5s ease-in;
}`

export const IconWrapper= styled.div`
padding: 20px 20px 0;
align-self: center;
display: flex;`

export const Icon= styled.div`
background: url("${props => props.image}") center center no-repeat;
height: 130px;
width: 130px;`

export const Image = styled.img`
height: 100%;
width: 100%;
min-height: 130px;
max-height: 200px;
@media screen and (min-width: 992px){
    min-height: 200px;
    max-height: 400px;
}`

export const Text = styled.div`
font-size: ${props => props.size};
font-weight: ${props => props.weight};
color: ${props => props.color};
line-height: 2;
padding: 10px 0;`


export const TextBold = styled.div`
font-size: ${props => props.size};
font-weight: bold;`

export const Form = styled.form`
max-width: 450px;
@media (max-width: 992px) {
    max-width: 350px;
}`
export const Input = styled.input`
width: -webkit-fill-available;
padding: 10px 20px;
border: none;
border-radius: 25px;
background-color: var(--white);
color: var(--gray);
font-size: 15px;
font-weight: 400;
outline: none;
::placeholder {
    color: #676AD2;
    font-size: 14px;
    font-weight: 400;
}
:focus {
border: 1px solid #676AD2;
}
`
export const FormInput = styled.div`
padding: 0 20px;
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 100%;
position: relative;
min-height: 12px;
margin-bottom: 1.3rem!important;`

export const  ScrollTop = styled.div`
display: none;
position: fixed;
bottom: 20px;
right: 30px;
z-index: 99;
font-size: 25px;
border: none;
outline: none;
background-color: var(--primary);
box-shadow: -4px 0px 10px 0px #33333347;
color: white;
cursor: pointer;
padding: 10px;
border-radius: 100%;
height: 30px;
text-align: center;
width: 30px;
transition: 1s all ease;`

export const Paragraph = styled.p`
margin:10px auto;
`;