import Layout from '../components/Layout'
import '../styles/globals.css'
import '../styles/animate.css'
import '../styles/uicons-brands/css/uicons-brands.css';
import '../styles/uicons-regular-rounded/css/uicons-regular-rounded.css';
import React, { useEffect, useState } from 'react'
import Head from 'next/head';
import Router from 'next/router';
import Loader from '../components/Loader';
import NProgress from 'nprogress';
NProgress.configure({ showSpinner: false });

function MyApp({ Component, pageProps }) {
  const [domLoaded, setDomLoaded] = useState(false);
const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    Router.events.on('routeChangeStart', (route)=>{
      setIsLoading(true)
      NProgress.start();
    })
    Router.events.on('routeChangeComplete', (route)=>{
       setIsLoading(false)
      NProgress.done();
    })
    setDomLoaded(true);
  }, []);

  

  return (
   
    <React.StrictMode> 
      {/* {domLoaded && ( */}
  <Layout>
    <Head>
    <title>Medville Engineering and Services</title>
      </Head>
      {isLoading ? <Loader/> : <Component {...pageProps} />
      }
   
  </Layout>
  {/* )} */}
  </React.StrictMode>
    
  )
}

export default MyApp
